<template>
    <div class="app-body ">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
        </div>
        <el-card class="el-main">
            <el-form :model="form" ref="form" label-width="125px" label-position="left">
                <el-form-item label="提交模式" prop="billWay">
                    <el-radio-group v-model="form.billWay" @change='billWayChange'>
                        <el-radio :label="1">账单缴费</el-radio>
                        <el-radio :label="2">电表缴费</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="电费项目" label-width="125px" prop="projectId" v-if="form.billWay == 1">
                    <le-select-remote-search
                        large
                        style="padding: 0 !important; width: 300px" 
                        v-model="form.projectId" 
                        :options="optionsProject" 
                        placeholder="请选择电费项目(可搜索)" />
                </el-form-item>
                <el-form-item label="电表号" prop="ammeterId" v-if="form.billWay == 2">
                    <le-select-remote-search
                        large
                        style="padding: 0 !important; width: 300px" 
                        v-model="form.ammeterId" 
                        :options="optionsAmmeter" 
                        placeholder="请选择电表(可搜索)" />
                </el-form-item>
                <!-- <el-form-item prop="pictureList" label="发票">
                    <le-upload-file-img-ly ref="pic" v-model="form.pictureList" :limit='10'></le-upload-file-img-ly>
                </el-form-item> -->
                <electric-charge-add-bill 
                    :tableData.sync="tableDataBill" 
                    :chargeFeeType="chargeFeeType" 
                    @recordTimeChange='recordTimeChange'
                    @lastRecordTimeChange='lastRecordTimeChange'
                    v-if="form.billWay == 1">
                </electric-charge-add-bill>
                <electric-charge-add-ammeter 
                    :tableData.sync="tableDataAmmeter" 
                    :chargeFeeType="chargeFeeType" 
                    @recordTimeChange='recordTimeChange'
                    @lastRecordTimeChange='lastRecordTimeChange'
                    ref="electricChargeAddAmmeter"
                    v-if="form.billWay == 2">
                </electric-charge-add-ammeter>
                <div style="height: 70px;"></div>
            </el-form>
            
        </el-card>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
            <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">返回</el-button>
        </div>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import ElectricChargeAddAmmeter from './child/electric-charge-add-ammeter.vue'
    import electricChargeAddBill from './child/electric-charge-add-bill.vue'
    export default {
    components: { electricChargeAddBill, ElectricChargeAddAmmeter },
        data () {
            return {
                debounceTimeout: null,
                util: util,
                tableDataBill: [], // 账单缴费数据
                tableDataAmmeter: [], // 电表缴费数据
                chargeFeeType: '',//电费收取方式:1:预充值,2先用后付
                form: {
                    billWay: 1,
                    projectId: '',
                    ammeterId: '',
                    currentDate: this.$getDay.getToday(),
                    lastDate: '',
                },
                optionsProject: {
                    url: this.$Config.apiUrl.electricFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                optionsAmmeter: {
                    url: this.$Config.apiUrl.ammeterFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                }
            }
        },
        watch: {
            'form.projectId': {
                handler (val) {
                    if(val) {
                        this.getBillInfoForm()
                    }
                }
            },
            'form.ammeterId': {
                handler (val) {
                    if(val) {
                        this.getBillInfoForm()
                    }
                }
            },
            'form.currentDate': {
                handler (val) {
                    if(val) {
                        this.getBillInfoForm()
                    }
                }
            },
            'form.lastDate': {
                handler (val) {
                    if(val) {
                        this.getBillInfoForm()
                    }
                }
            }
        },
        methods:{
            billWayChange () {
                this.form.ammeterId = ''
                this.form.projectId = ''
            },
            recordTimeChange (val) {
                this.form.currentDate = val
            },
            lastRecordTimeChange (val) {
                this.form.lastDate = val
            },
            getBillInfoForm () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.electricBillInfo,
                    method: "post",
                    params: {
                        ...this.form
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        let resData = res.result.data
                        let arr = []
                        resData.billAmmeterRecordResultList.map(item=>{
                            let obj = {
                                ...item,
                                currentFee: resData.currentFee,
                                intervalRatio: resData.intervalRatio,
                                projectId: resData.projectId,
                                projectName: resData.projectName,
                                ratio: resData.ratio,
                                totalEnergy: resData.totalEnergy,
                                totalPay: resData.totalPay,
                                totalProfit: resData.totalProfit,
                                unitPrice: resData.unitPrice,
                                recordTime: this.form.currentDate || this.$getDay.getToday(),
                                lastRecordTime: this.form.lastDate || item.lastRecordTime,
                                lastRecordTimeReadoOnly: item.lastRecordTime, 
                                deviceNumId: item.ammeterId,
                                recordTimeId: item.projectId,
                                recordPowerId: item.ammeterId,
                                remainMoneyId: item.ammeterId,
                                prePayAmountId: item.ammeterId,
                                needPayAmountId: item.ammeterId,
                                lastRecordTimeId: item.projectId,
                                lastRecordPowerId: item.ammeterId,
                                lastPrePayAmountId: item.ammeterId,
                                lastRemainMoneyId: item.ammeterId,
                                unitPriceId: resData.projectId,
                                intervalIncomeId: item.stationId,
                                stationIncomeRatioId: item.ammeterId,
                                totalProfitId: resData.projectId,
                                totalPayId: resData.projectId,
                                intervalRatioId: item.ammeterId,
                                ratioId: resData.projectId,
                            }
                            arr.push(obj)
                        })
                        let uniqArr = JSON.parse(JSON.stringify(arr))
                        uniqArr = this.uniqueArrAmmeter(uniqArr)
                        let totalProfit = 0
                        arr.map(item=>{ // 按比例计算收益合计
                            totalProfit += (Number(item.intervalIncome || 0) * Number(item.stationIncomeRatio))
                        })
                        this.chargeFeeType = resData.chargeFeeType || 1
                        if(this.form.billWay == 1) {
                            this.tableDataBill = arr.map(item=>{
                                return {
                                    ...item,
                                    totalProfit: totalProfit,
                                    deviceNumByRadio: item.deviceNumByRadio || '0',
                                    slotNumByRadio: item.slotNumByRadio || '0'
                                }
                            })
                        }else if(this.form.billWay == 2) {
                            this.tableDataAmmeter = arr.map(item=>{
                                return {
                                    ...item,
                                    totalProfit: totalProfit,
                                    deviceNumByRadio: item.deviceNumByRadio || '0',
                                    slotNumByRadio: item.slotNumByRadio || '0'
                                }
                            })

                            this.$refs['electricChargeAddAmmeter'].originTableData = JSON.parse(JSON.stringify(this.tableDataAmmeter))
                        }
                        
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            async submit () {
                let requestData = []
                if(this.form.billWay == 1) {
                    requestData = this.tableDataBill
                }else if(this.form.billWay == 2) {
                    requestData = this.tableDataAmmeter
                }
                let checkResult = await this.checkRules(requestData)
                if(checkResult) {
                    this.$message.error(checkResult)
                    return
                }

                let reqData = requestData.map(item=>{
                    return {
                        ...item,
                        needPayAmount:  this.chargeFeeType == 2 ? Number((item.needPayAmount*100).toFixed(0)) : item.needPayAmount,
                        prePayAmount: (item.prePayAmount*100).toFixed(0),
                        remainMoney: (item.remainMoney*100).toFixed(0),
                        finalRemainMoney: ((Number(item.remainMoney) + Number(item.prePayAmount)) * 100).toFixed(0),
                        intervalRatio: (item.intervalRatio*100).toFixed(0),
                        ratio: (item.ratio*100).toFixed(0),
                    }
                })
                this.$confirm('是否确认提交？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.electricBillAdd,
                        method: "post",
                        params: {
                            ammterBillList: reqData,
                            billDate: reqData[0].recordTime,//账单时间
                            billDateStart: reqData[0].lastRecordTime,//账单开始时间 - 取上次抄表时间
                            chargeFeeType: this.chargeFeeType,
                            projectId: reqData[0].projectId,
                            projectPowerRate: reqData[0].ratio,//总电费收益占比 * 100
                            totalIntervalFee: reqData[0].totalPay,//应付合计
                            totalIntervalIncome: reqData[0].totalProfit,//收益合计
                            totalIntervalPower: reqData[0].totalIntervalPower,//抄表总度数
                            unitPrice: reqData[0].unitPrice,//电费单价
                            billWay: this.form.billWay
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.$router.back()
                            this.$store.dispatch("delView", this.$route);
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            checkRules (datas) {
                return new Promise((resolve, reject) => {
                    if(datas.length && datas){
                        for (let index = 0; index < datas.length; index++) {
                            const item = datas[index];
                            if(!item.recordTime){
                                resolve(`请选择电表号：${item.ammeterNo}的本次抄表日期`)
                                return
                            }
                            if(this.$getDay.getTimeUnix(item.recordTime) < this.$getDay.getTimeUnix(item.lastRecordTime)){
                                resolve(`抄表日期填写有误：电表号：${item.ammeterNo}的本次抄表日期小于上次抄表日期`)
                                return
                            }
                            if(item.recordPower === ''){
                                resolve(`请填写电表号：${item.ammeterNo}的本次抄表度数`)
                                return
                            }
                            if(item.remainMoney === '' && this.chargeFeeType == 1){
                                resolve(`请填写电表号：${item.ammeterNo}的剩余费用`)
                                return
                            }
                            if(item.prePayAmount === '' && this.chargeFeeType == 1){
                                resolve(`请填写电表号：${item.ammeterNo}的预缴费用`)
                                return
                            }
                            if(item.needPayAmount === '' && this.chargeFeeType == 2){
                                resolve(`请填写电表号：${item.ammeterNo}的应付电费`)
                                return
                            }
                            if(!item.deviceNumByRadio){
                                resolve(`请填写电表号：${item.ammeterNo}的设备数量`)
                                return
                            }
                            if(!item.slotNumByRadio){
                                resolve(`请填写电表号：${item.ammeterNo}的插槽`)
                                return
                            }
                        }
                        resolve()
                    }else{
                        resolve()
                    }
                })
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].stationId==arr[j].stationId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
            uniqueArrAmmeter (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].ammeterId==arr[j].ammeterId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.scrollBar{
    left: 200px ;
    right: 48px ;
    position: fixed;
    bottom: 70px;
    background: #fff;
    z-index: 100;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
/deep/ .el-input-group__prepend{
    height: 32px;
    line-height: 32px;
}
/deep/ .el-date-editor.el-input{
    width: 140px !important;
}
/deep/ .el-main .el-card__body::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 10px !important;
    height: 10px !important;
    display: block !important;
}
</style>